import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { blockScreenStateRecoil } from './recoil/blockscreenState';
import { useRecoilState } from 'recoil';

interface IBlockScreen {}

export const BlockScreen: React.FC<IBlockScreen> = () => {
    const [blockScreenState] = useRecoilState(blockScreenStateRecoil);

    return (
        <>
            {blockScreenState.show && (
                <div className="fixed top-0 left-0 z-[1500] flex h-screen w-screen items-center justify-center bg-black/70 shadow-xl backdrop-blur-sm">
                    <FontAwesomeIcon
                        icon={faSync}
                        className="animate-spin text-7xl text-white/80"
                    />
                </div>
            )}
        </>
    );
};
