import { toast } from 'react-toastify';
import localforage from 'localforage';

export const showOfflineMessage = () => {
    toast.error('Du bist offline');
};

export const lfOfflineCache = localforage.createInstance({
    name: 'cache',
    version: 4,
});

class AsyncLF {
    private lf = lfOfflineCache;

    async getItem(key: string): Promise<string | null> {
        return this.lf.getItem(key);
    }

    async setItem(key: string, value: string): Promise<void> {
        await this.lf.setItem(key, value);
    }

    async removeItem(key: string): Promise<void> {
        await this.lf.removeItem(key);
    }
}

export const lfStorage = new AsyncLF();

export interface OfflineCache<T> {
    buster: string;
    clientState: {
        queries: {
            queryHash: string;
            state: { status: string; data: T; dataUpdatedAt: number };
        }[];
    };
    timestamp: number;
}

export const REACT_QUERY_KEY = 'REACT_QUERY_OFFLINE_CACHE';
