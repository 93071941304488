import { atom } from 'recoil';
import { nanoid } from 'nanoid';

export interface IBlockScreenState {
    show: boolean;
}

export const blockScreenStateRecoil = atom<IBlockScreenState>({
    key: `block-screen-state/${nanoid()}`,
    default: {
        show: false,
    },
});
