// global css
// ! don't forget to add them to .storybook/preview.js too!
import '../styles/globals.scss';
// import 'simplebar/dist/simplebar.min.css';
import { Flip, ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import { AppProps } from 'next/app';
import { lfStorage } from '../helper/offline';
import { BlockScreen } from '../components/atoms/Blockscreen/Blockscreen';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { PersistedClient, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import Jimp from 'jimp';
import JPEG from 'jpeg-js';

//DON'T MOVE OUTSIDE OF APP
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: Infinity, // 24 hours
            retry: 3,
            staleTime: 60000,
        },
        mutations: {
            retry: 1,
        },
    },
});

const persister = createAsyncStoragePersister({
    storage: lfStorage,
    throttleTime: 100,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    deserialize: (cachedString) => cachedString as unknown as PersistedClient,
    serialize: (client) => client as unknown as string,
});

// Search for all occurancies of this setting, they be be overwritten by the next line
Jimp.decoders['image/jpeg'] = (data: Buffer) => JPEG.decode(data, { maxMemoryUsageInMB: 1024 });

function MyApp({ Component, pageProps }: AppProps) {
    return (
        <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister, maxAge: Infinity }}
        >
            <RecoilRoot>
                <ToastContainer
                    position="top-center"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    transition={Flip}
                />

                <BlockScreen />
                <Component {...pageProps} />
            </RecoilRoot>
            <ReactQueryDevtools initialIsOpen={false} />
        </PersistQueryClientProvider>
    );
}

export default MyApp;
